type Breakpoint = 'default' | 'xl' | 'lg' | 'md' | 'sm' | 'xs';

export const builderGrid: Record<Breakpoint, string> = {
  default: '3',
  xl: '3',
  lg: '4',
  md: '6',
  sm: '6',
  xs: '12',
};
